<template>
	<div style="padding:10px 0;">
		<div style="display:flex;">
			<div style="flex: 1;">
				<img src="~@assets/images/logo2.png" style="width:100%;" />
			</div>
			<div style="flex: 1;color:#606266;text-align: right;">
				<p style="margin:0 0 2px 0;color: #606266;font-size: 14px;display: inline-block;width: 120px;text-align: left;">电话热线</p>
				<p style="font-weight: 600;margin: 0;text-align: right;color:#0084C4;font-size: 15px;display: inline-block;width: 120px;text-align: left;">020-39392639
				</p>
			</div>
		</div>
		<van-tabs v-model="newActive" style="margin-bottom: 10px;" @click="onClick">
			<van-tab title="首页" name="/mobile/home"></van-tab>
			<van-tab title="招标信息" name="/mobile/tender"></van-tab>
			<van-tab title="公司业务" name="/mobile/companyActive"></van-tab>
			<van-tab title="关于我们" name="/mobile/aboutUs"></van-tab>
		</van-tabs>
		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" >
			<van-swipe-item v-for="(item,index) in imgs" :key="index">
				<img :src="item.src" style="width:100%;" />
			</van-swipe-item>
		</van-swipe>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {Swipe,SwipeItem,Lazyload,Tab, Tabs} from 'vant';
	Vue.use(Swipe);
	Vue.use(SwipeItem);
	Vue.use(Tab);
	Vue.use(Tabs);
	Vue.use(Lazyload);
	export default {
		name: 'mobileHeader',
		props: {
			active:{
				type:String,
				default:'/mobile/home'
			}
		},
		data() {
			return {
				imgs:[],
				newActive:this.active,//这里可以防止子组件，修改父组件的值
			}
		},
		mounted:function(){
			this.bus.$on('headerImg',data=>{
				this.imgs=data;
			})
		},
		methods:{
			onClick(name) {
				this.$router.push(name);
			},
		}
	}
</script>

<style>
	.my-swipe .van-swipe-item {
		color: #fff;
		font-size: 20px;
		text-align: center;
		background:#fff;
	}
</style>
